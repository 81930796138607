import GlobalStyle from './styles/GlobalStyles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './components/Homepage';
import Contact from './components/Contact';
import BlogIndex from './pages/BlogIndex';
import "tailwindcss/base.css";
import "./styles/globalStyles.css";
import Header from './components/Header';
import BlogPost from './pages/BlogPost';
import Footer from './components/Footer';
import React, { useEffect, useState } from 'react';
import Projects from './components/Projects';
import axios from 'axios';
import Projects2 from './components/Projects2';
import PlayingWithDesign from './components/PlayingWithDesign';


function App() {

  const [currentSong, setCurrentSong] = useState(null);

  const fetchSong = async () => {
    const params = new URLSearchParams();

    params.append('client_id', process.env.REACT_APP_SPOTIFY_CLIENT_ID);
    params.append('client_secret', process.env.REACT_APP_SPOTIFY_CLIENT_SECRET);
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN);

    await axios.post('https://accounts.spotify.com/api/token', params).then(res => {
      const accessToken = res.data.access_token;
      const url = `https://api.spotify.com/v1/me/player/currently-playing?access_token=${accessToken}`;
      axios.get(url).then(res => {
        setCurrentSong(res.data.item);
        console.log(res.data.item);
      });
    });
  }

  // get current playing song from spotify api
  useEffect(() => {
    fetchSong();
    const interval = setInterval(() => {
      fetchSong();
    }, 100000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<BlogIndex />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/projects-2" element={<Projects2 />} />
        <Route path="/projects/playing-with-design" element={<PlayingWithDesign />} />
      </Routes>
      <Footer
        spotifyImage={currentSong ?
          <img src={currentSong.album.images[2].url} alt={currentSong.album.name} />
          : <h3>Nothing at the moment</h3>}
        spotifySongName={currentSong ? currentSong.name : null}
        // Map artists to a string
        spotifySongArtist={currentSong ? currentSong.artists.map(artist => artist.name).join(', ') : null}
        nowPlayingWrapper={{ href: (currentSong ? currentSong.external_urls.spotify : null) }}
      />
    </Router>
  );
}

export default App;
