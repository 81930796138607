// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState } from "react";
import { PlasmicContact } from "./plasmic/portfolio_website/PlasmicContact";
import { httpsCallable } from 'firebase/functions';
import { functions } from "../firebase";

function Contact_(props, ref) {
  // Use PlasmicContact to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicContact are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ContactProps here, but feel free
  // to do whatever works for you.
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const sendEmail = (data) => {
    const sendEmailFunction = httpsCallable(functions, 'sendEmail');

    if (data.firstName === "") {
      setErrorMessage("First name is required");
      setError(true);
      setLoading(false);
      return;
    }

    if (data.lastName === "") {
      setErrorMessage("Last name is required");
      setError(true);
      setLoading(false);
      return;
    }

    if (data.email === "") {
      setErrorMessage("Email is required");
      setError(true);
      setLoading(false);
      return;
    }

    if (data.message === "") {
      setErrorMessage("Message is required");
      setError(true);
      setLoading(false);
      return;
    }

    sendEmailFunction({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      message: data.message
    }).then(result => {
      console.log(result);
      if (result.data.status === "success") {
        setSuccess(true);
        setLoading(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
      } else {
        console.log(result.data.message);
        setError(true);
        setLoading(false);
        setErrorMessage("Something went wrong. Please try again later.");
      }
    }).catch(error => {
      console.log(error);
      setError(true);
      setErrorMessage(error.message);
      setLoading(false);
    });
  };


  return <PlasmicContact root={{ ref }}
    firstName={{
      value: firstName,
      onChange: (e) => {
        setFirstName(e.target.value);
        setFormData({
          ...formData,
          firstName: e.target.value
        });
      }
    }}
    lastName={{
      value: lastName,
      onChange: (e) => {
        setLastName(e.target.value);
        setFormData({
          ...formData,
          lastName: e.target.value
        });
      }
    }}
    email={{
      value: email,
      onChange: (e) => {
        setEmail(e.target.value);
        setFormData({
          ...formData,
          email: e.target.value
        });
      }
    }}
    message={{
      value: message,
      onChange: (e) => {
        setMessage(e.target.value);
        setFormData({
          ...formData,
          message: e.target.value
        });
      }
    }}
    sendMessageBtn={{
      onClick: () => {
        setLoading(true);
        setSuccess(false);
        setError(false);
        setErrorMessage("");
        sendEmail(formData);
      }
    }}
    messageResult={
      success ?
        <div>Thanks for your message! I'll get back to you as soon as possible.</div>
        :
        error ?
          <div>{errorMessage}</div>
          :
          loading ?
            <div>Sending message...</div>
            :
            null
    }
  />;
}

const Contact = React.forwardRef(Contact_);

export default Contact;
