import React, { useEffect, useState } from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading } from "../components/misc/Headings";
import { PrimaryButton } from "../components/misc/Buttons";
import sanityClient from '../sanity';
import ReactLoading from "react-loading";
import { Helmet } from "react-helmet";

export default function BlogIndex() {

  const HeadingRow = tw.div`flex xs:mx-2`;
  const Heading = tw(SectionHeading)`text-gray-900`;
  const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
  const PostContainer = styled.div`
${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
${props =>
      props.featured &&
      css`
  ${tw`w-full!`}
  ${Post} {
    ${tw`sm:flex-row! h-full sm:pr-4`}
  }
  ${Image} {
    ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
  }
  ${Info} {
    ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
  }
  ${Description} {
    ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
  }
  `}
  `;
  const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg xs:mx-2 sm:mx-2 md:mx-4 lg:mx-0`;
  const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
  `;
  const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
  const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
  const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
  const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
  const Description = tw.div``;

  const ButtonContainer = tw.div`flex justify-center`;
  const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };


  const [allPosts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllPosts();
  }, []);

  // Format the date to MMM DD, YYYY
  const formatDate = (date) => {
    const d = new Date(date);

    const monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun", "Jul",
      "Aug", "Sept", "Oct",
      "Nov", "Dec"
    ];

    const day = d.getDate();
    const monthIndex = d.getMonth();
    const year = d.getFullYear();

    return monthNames[monthIndex] + ' ' + day + ', ' + year;
  }

  const fetchAllPosts = () => {
    sanityClient
      .fetch(
        `*[_type == "post"] | order(publishedAt desc) {
    title,
    slug,
    featured,
    publishedAt,
    description,
    mainImage{
      asset->{
      _id,
      url
    }
  }
}`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error)
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      });
  }

  if (loading) {
    return (
      <LoadingContainer>
        <ReactLoading type="bars" color="#eaeaea" />
      </LoadingContainer>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Blog | Scott Hladun</title>
        <meta name="description" content="Scott Hladun's blog about learning, technology and entrepreneurship." />
        <meta name="keywords" content="Scott Hladun, blog, learning, technology, entrepreneurship" />
        <meta name="author" content="Scott Hladun" />
      </Helmet>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Recent Posts</Heading>
          </HeadingRow>
          <Posts>
            {console.log(allPosts)}
            {allPosts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                {console.log(post)}
                <Post className="group" as="a" href={`blog/${post.slug.current}`}>
                  {post.mainImage && <Image imageSrc={post.mainImage.asset.url} />}
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{formatDate(post.publishedAt)}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < allPosts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
    </div >
  );
}


const LoadingContainer = styled.div`
    ${tw`flex flex-col items-center justify-center`}
    height: 100vh;
`;