/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import * as THREE from "three"
import { useFrame } from '@react-three/fiber';

export default function FidgetCube(props) {
  const { nodes, materials } = useGLTF('/FidgetCube.gltf')
  const materialBlue = new THREE.MeshToonMaterial({ color: '#153a68' });
  const materialGrey = new THREE.MeshToonMaterial({ color: '#555555' });
  const materialBlack = new THREE.MeshToonMaterial({ color: '#222222' });
  const matcap = useTexture('/matcaps/matcap-glossy-black.png');
  const fidgetCubeRef = useRef();
  useFrame(() => {
    [fidgetCubeRef]
      .forEach((ref) => {
        ref.current.rotateX(0.005);
        ref.current.rotateY(0.005);
        ref.current.rotateZ(0.005);
      })
  })
  return (
    <group {...props} dispose={null} position={[0, 0, 0]}>
      <group scale={0.005} ref={fidgetCubeRef}>
        <mesh geometry={nodes.Fidget_Cube_v8_1.geometry} material={materialBlack} />
        {/* <mesh geometry={nodes.Fidget_Cube_v8_2.geometry} material={materials['Plastic_-_Matte_(Black)']} /> */}
        <mesh geometry={nodes.Fidget_Cube_v8_2.geometry} material={materialBlue} />
        <mesh geometry={nodes.Fidget_Cube_v8_3.geometry} material={materialGrey} />
      </group>
    </group>
  )
}

useGLTF.preload('/FidgetCube.gltf')
