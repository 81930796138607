import React, { useState, useEffect } from 'react';
import sanityClient from '../sanity';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Container } from '../components/misc/Layouts';
import ReactLoading from 'react-loading';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import { Helmet } from 'react-helmet';
import Lowlight from 'react-lowlight';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/github-dark-dimmed.css'
import YouTube from 'react-youtube';
import getYouTubeId from 'get-youtube-id';

const builder = imageUrlBuilder(sanityClient);
function UrlFor(source) {
    return builder.image(source);
}

Lowlight.registerLanguage('javascript', javascript);
Lowlight.registerLanguage('json', json);

export default function BlogPost() {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug } = useParams();

    const fetchBlogPost = () => {
        sanityClient.fetch(`*[slug.current == $slug]{
            title,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            mainImageSource,
            body,
            publishedAt,
            "name": author->name,
            "image": author->image
        }`, { slug })
            .then(data => setPost(data[0]))
            .catch(err => setError(err))
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            });
    }

    const formatDate = (date) => {
        const d = new Date(date);

        const monthNames = [
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sept", "Oct",
            "Nov", "Dec"
        ];

        const day = d.getDate();
        const monthIndex = d.getMonth();
        const year = d.getFullYear();

        return monthNames[monthIndex] + ' ' + day + ', ' + year;
    }

    useEffect(() => {
        fetchBlogPost();
    }, [slug]);


    if (loading) {
        return (
            <LoadingContainer>
                <ReactLoading type={'bars'} color={'#eaeaea'} />
            </LoadingContainer>
        );
    }

    if (error) {
        return (
            <ErrorContainer>
                <h1>Error</h1>
                <p>{error.message}</p>
            </ErrorContainer>
        );
    }

    return (
        <Container>
            <Helmet>
                <title>{post.title}</title>
                <meta name="description" content={post.description} />
                <meta name="keywords" content="Scott Hladun, blog, learning, technology, entrepreneurship" />
                <meta name="author" content={post.name} />
            </Helmet>
            <PostWrapper>
                <Container>
                    <PostAndImage>
                        <div>
                            {post.mainImage && <MainImage src={UrlFor(post.mainImage.asset.url)} alt={post.title} />}
                            {post.mainImageSource && <ImageAttribution dangerouslySetInnerHTML={{ __html: post.mainImageSource }} ></ImageAttribution>}
                        </div>
                        <PostContent>
                            <AuthorTile>
                                {post.image && <AuthorImage src={UrlFor(post.image)} alt={post.name} />}
                                <div>
                                    {post.name && <PostAuthor>{post.name}</PostAuthor>}
                                    {post.publishedAt && <PostDate>{formatDate(post.publishedAt)}</PostDate>}
                                </div>
                            </AuthorTile>
                            <PostTitle>{post.title}</PostTitle>
                            <div>
                                {console.log(post)}
                                <BlockContent blocks={post.body} serializers={PortableTextComponents} />
                            </div>
                        </PostContent>
                    </PostAndImage>
                </Container>
                {/* <Container>
                <ContentWithPaddingXl>
                    <div>
                        Side Bar!!!!
                    </div>
                </ContentWithPaddingXl>
            </Container> */}
            </PostWrapper>
        </Container>
    );
}

const PortableTextComponents = {
    types: {
        block: (props) => {
            console.log(props.node.children.marks);
            switch (props.node.style) {
                case 'h1':
                    return <h1>{props.children}</h1>;
                case 'h2':
                    return <h2>{props.children}</h2>;
                case 'h3':
                    return <PostH3>{props.children}</PostH3>;
                case 'h4':
                    return <PostH4>{props.children}</PostH4>;
                case 'normal':
                    return <PostBlock>{props.children}</PostBlock>
                default:
                    return <PostBlock>{props.children}</PostBlock>;
            }

        },
        // links: ({ mark, children }) => {
        //     const { href, blank } = mark;
        //     return blank ?
        //         <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
        //         : <a href={href}>{children}</a>
        // },
        code: (props) => (
            <PostCodeWrapper>
                <Lowlight language={props.node.language} value={props.node.code} />

            </PostCodeWrapper>
        ),
        image: (props) => (
            <PostImage src={UrlFor(props.node.asset).url()} alt="" />
        ),
        youtube: ({ node }) => {
            const { url } = node
            const id = getYouTubeId(url)
            return (<YouTube videoId={id} />)
        },
        Iframe: ({ node }) => {
            const { url } = node
            return (
                <PostIframe src={url} allowFullScreen='allowfullscreen' allow='fullscreen' />
            )
        }
    },
};

const LoadingContainer = styled.div`
    ${tw`flex flex-col items-center justify-center`}
    height: 100vh;
`;

const ErrorContainer = styled.div`
    ${tw`flex flex-col items-center justify-center`}
    height: 100vh;
`;

const PostWrapper = styled.div`
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;

                ${tw`flex flex-row items-start w-full`}
`;

const MainImage = styled.img`
                ${tw`w-screen h-auto sm:h-auto md:h-auto lg:h-auto xl:h-auto`}
                height: auto;
                max-height: 400px;
                object-fit: cover;
                max-width: 100%;
`;

const ImageAttribution = styled.div`
                ${tw`text-xs text-gray-600`}
                a {
                    ${tw`text-blue-600`}

                &:hover {
                    ${tw`underline`}
        }
    }
`;

const PostContent = styled.div`
    ${tw`w-3/4 mx-auto mt-8`}
`;

const PostAndImage = styled.div`
    ${tw`max-w-screen-xl sm:py-5 lg:py-12 block`}
`;

const PostIframe = styled.iframe`
    ${tw`w-full h-128`}
`;

const AuthorTile = styled.div`
    ${tw`flex flex-row items-center mb-8`}
`;

const AuthorImage = styled.img`
    ${tw`w-12 h-12 object-cover rounded-full mr-4`}
`;

const PostAuthor = styled.h3`
    ${tw`text-base font-bold text-primary-500`}
`;

const PostDate = styled.p`
                ${tw`text-xs text-gray-600`}
`;

const PostTitle = styled.h1`
                font-size: 2.5rem;
                font-weight: 700;
                margin-bottom: 1rem;
                color: #0F2441;
                text-align: center;
`;

const PostBlock = styled.div`
                margin-bottom: 1rem;
`;

const PostH3 = styled.h3`
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: #0F2441;
`;

const PostH4 = styled.h4`
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: #0F2441;
`;

const PostImage = styled.img`
                ${tw`w-full h-auto object-cover rounded-lg max-w-full`}
                // ${tw`hidden`}
`;

const PostCodeWrapper = styled.div`
                ${tw`w-full h-auto overflow-x-scroll max-w-full xs:max-w-xs sm:max-w-sm md:max-w-full lg:max-w-full xl:max-w-full`}
`;