import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    font-family: 'Montserrat', Sans-Serif;
  }

  code {
    background-color: #eaeaea;
    border-radius: 5px;
    padding: 0.2rem 0.4rem;
  }

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }

`;

export default GlobalStyle;