import React, { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import FidgetCube from './FidgetCube'
import { Float, OrbitControls, Stars, useTexture } from '@react-three/drei'
import { Grid, Typography } from '@mui/material';
import { Widget } from '@typeform/embed-react'
import YoutubeEmbed from './YoutubeEmbed';

export default function PlayingWithDesign() {
    return (
        <div style={{
            position: 'relative',
            background: '#1F262A'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>
                <h1 style={{
                    color: 'whitesmoke',
                    fontSize: '44px',
                    textAlign: 'center'
                }}>
                    How I Like To Focus
                </h1>
            </div>
            <Canvas
                camera={{ position: [2, 0, 12.25], fov: 15 }}
                style={{
                    minHeight: '300px',
                    height: '400px'
                }}>
                <ambientLight intensity={1.25} />
                <ambientLight intensity={0.1} />
                <directionalLight intensity={2.8} position={[0, 1, 0]} />
                <Suspense fallback={null}>
                    <FidgetCube position={[0.5, 5, 5]} />
                </Suspense>
                <OrbitControls enableZoom={false} />
            </Canvas>
            <Grid container spacing={8} sx={{
                px: 5
            }}>
                <Grid item xs={12} md={6} sx={{
                    px: 3
                }} >
                    <Typography variant='h3' sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'right'
                        },
                        color: 'whitesmoke',
                        fontSize: '32px',
                        fontFamily: 'Montserrat',
                        fontWeight: '800',
                    }}>
                        I'm a fidgeter
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant='body1' sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'left'
                        },
                        color: 'whitesmoke',
                        fontFamily: 'Montserrat',
                    }} >Typically you'll find me bouncing my leg, twiddling my thumbs, or any number of other fidgeting ticks I've developed. Myself, along with many others have become over stimulated by our surroundings; constant notifications, mobile games and apps at our finger tips, switching from tasks constantly. It's no wonder we have a hard time sitting still and focusing. </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item container xs={12} md={12} justifyContent='center'>

                    <Widget id='TvbZaChB' style={{
                        width: {
                            md: '50%',
                            xs: '100%'
                        },
                        height: '600px',
                    }} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5} px={5} >
                <Grid item xs={12} md={6}>
                    <Typography variant='h3' sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'right'
                        },
                        color: 'whitesmoke',
                        fontSize: '32px',
                        fontFamily: 'Montserrat',
                        fontWeight: '800',
                    }}>
                        History of Fidgeting
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{
                    pr: {
                        xs: 0,
                        md: 15,
                    }
                }}>
                    <Typography variant='body1' sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'left'
                        },
                        color: 'whitesmoke',
                        fontFamily: 'Montserrat',
                    }}>
                        Every person requires a different amount of sensory stimulation during task completion, some people like listening to music while studying, and others require complete silence.
                        Some individuals with sensory needs, such as ADHD and autism, may struggle to sit for extended periods of time and require more frequent stimulation. This often leads to repetitive
                        actions or motions, like clicking a pen, tapping ones foot, or bouncing ones leg. Typically educators try to discourage this kind of fidgeting as it can distract other students, but
                        is this actually impacting the attention and focus of the student who can no longer fidget?
                        <br /> <br />
                        For myself, I find that I fidget in situations where I am uncomfortable or when I have an increased stress level. In these times I usually fidget with the buttons on my shirt, my phone case,
                        or anything else that's nearby or on my person.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent='center' sx={{
                py: 5,
            }}>
                <Grid item spacing={2} xs={10} md={6}>
                    <YoutubeEmbed embedId='71PB_Rulk5M' />
                    <Typography variant='body1' sx={{
                        textAlign: 'center',
                        color: 'whitesmoke',
                        fontFamily: 'Montserrat',
                    }}>
                        The Youtube channel, How to ADHD, provides a great explanation about the benefits of fidget toys.
                    </Typography>
                </Grid>
            </Grid>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>

                <h1 style={{
                    color: 'whitesmoke',
                    fontSize: '44px',
                    textAlign: 'center'
                }}>
                    So... What's my favourite fidget toy?
                </h1>
            </div>
            <Grid container spacing={2} sx={{
                px: 5,
                py: 4
            }}>
                <Grid item xs={12} md={5} >
                    <Typography variant='h3' sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'right'
                        },
                        color: 'whitesmoke',
                        fontSize: '32px',
                        fontFamily: 'Montserrat',
                        fontWeight: '800',
                    }}>
                        The Fidget Cube!
                    </Typography>
                    <Typography variant='body1' sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'right'
                        },
                        color: 'whitesmoke',
                        fontFamily: 'Montserrat',
                    }}>
                        This small fidget toy allows me to silently press buttons, flick switches, turn knobs, etc. I find that I'm able to focus more intensely when working on tasks, as well as limit more distracting fidgeting behaviour such as my leg always bouncing.
                        <br /> <br />
                        Check out the interactive 3D model to see for yourself!
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Canvas
                        camera={{ position: [2, 0, 12.25], fov: 15 }}
                        style={{
                            minHeight: '300px',
                            height: '400px'
                        }}>
                        <ambientLight intensity={1.25} />
                        <ambientLight intensity={0.1} />
                        <directionalLight intensity={2.8} position={[0, 1, 0]} />
                        <Suspense fallback={null}>
                            <FidgetCube position={[0.5, 5, 5]} />
                        </Suspense>
                        <OrbitControls enableZoom={false} />
                        <Stars />
                    </Canvas>
                </Grid>
            </Grid>
        </div >
    )
}
