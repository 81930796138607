// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function EmailsvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 512 512"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M388.816 169.245c-3.89-3.92-10.221-3.946-14.142-.057-3.92 3.89-3.946 10.222-.056 14.143l.06.062a9.897 9.897 0 007.054 2.941c2.548 0 5.1-.974 7.058-2.916 3.921-3.89 3.916-10.252.026-14.173z"
        }
      ></path>

      <path
        d={
          "M509.071 289.501l-92.878-92.878c-3.905-3.904-10.237-3.904-14.143 0-3.905 3.905-3.905 10.237 0 14.143l73.732 73.731H299.957c-14.586 0-26.451-11.866-26.451-26.451V82.222l71.183 71.182c3.905 3.904 10.237 3.904 14.143 0 3.905-3.905 3.905-10.237 0-14.143l-90.33-90.329c-3.905-3.904-10.237-3.904-14.142 0L94.94 208.355c-3.905 3.905-3.905 10.237 0 14.143l240.569 240.568a10.001 10.001 0 0014.142 0l159.421-159.422a10.003 10.003 0 00-.001-14.143zM253.508 78.071v127.356H126.152L253.508 78.071zm79.072 353.781L126.152 225.426h127.356v32.62c0 25.613 20.838 46.451 46.451 46.451h32.621v127.355zm19.999.001V304.497h127.355L352.579 431.853zM68.631 253.999H10c-5.523 0-10 4.478-10 10s4.477 10 10 10h58.631c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10zm29.815 0h-.15c-5.523 0-10 4.478-10 10s4.477 10 10 10h.15c5.523 0 10-4.478 10-10s-4.477-10-10-10zm-56.43-104.997H10.013c-5.523 0-10 4.478-10 10s4.477 10 10 10h32.003c5.523 0 10-4.478 10-10s-4.477-10-10-10zm70.494 0H74.682c-5.523 0-10 4.478-10 10s4.477 10 10 10h37.828c5.523 0 10-4.478 10-10s-4.477-10-10-10zm21.772 159.996H78.283c-5.523 0-10 4.478-10 10s4.477 10 10 10h55.999c5.523 0 10-4.478 10-10s-4.478-10-10-10zm43.726 63.998H37.009c-5.523 0-10 4.478-10 10s4.477 10 10 10h140.999c5.523 0 10-4.478 10-10s-4.477-10-10-10z"
        }
      ></path>
    </svg>
  );
}

export default EmailsvgIcon;
/* prettier-ignore-end */
